export const FEATURE_FLAGS = {
  suggestChange: {
    description: 'Suggest Change Web-Form',
    jiraLink: 'https://jira.fishbrain.com/browse/FB-39052',
  },
  heic: {
    description: 'test heic image upload',
    jiraLink: 'https://fishbrain.atlassian.net/browse/FIB-41691',
  },
  bassOrNot: {
    description: 'test bass or not',
    jiraLink: 'https://fishbrain.atlassian.net/browse/FIB-45219',
  },
  newTopSpeciesCard: {
    description: 'New Top Species Card on city pages',
    jiraLink: 'https://fishbrain.atlassian.net/browse/FIB-45573',
  },
} as const;

// 'test' and 'test2' is needed for the unit tests - should not be used for actual feature flags.
export type FeatureFlag = keyof typeof FEATURE_FLAGS | 'test' | 'test2';
